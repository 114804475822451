export const constants = {
    id : "#",
    status:"Status",
    lessonType:"Lesson type",
    technologies:"Technologies",
    whatHappened:"What happened?",
    howDidYouResolve:"How did you resolve?",
    foundByWhom:"Found by whom",
    projectName:"Project name",
    actions:"ACtions",
    open:"Open",
    close:"Close",
    programmatic:"Programmatic",
    logical:"Logical",
    technical:"Technical",
    functional:"Functional",
    others:"Others",
    title:"Lessons Learned Repository",
    actions:"Actions",
    deleteConfirmation:"Are you sure you want to delete this item?",
    add:"Add the Learned Lesson",
    edit:"Edit the Learned Lesson"
}
export const urls ={
    //***********development env************* */
    // REACT_APP_API_BASE_URL :"http://debug.teamta.net:5000/",
    // REACT_APP_SSO_APP_ID:"50c721bb-9990-4df1-b8b2-05e3f7fc11d0",
    // BACKEND_URL:"http://localhost:5000",
    //**********Staging env****************** */
    REACT_APP_API_BASE_URL:"https://lessonlearnerstaging.teamta.net",
    BACKEND_URL:"https://lessonlearnerstaging.teamta.net",
    REACT_APP_SSO_APP_ID:"38e94118-9052-4aef-adc6-0f4d69d8b3a7",
    //***********Production env************** */
    // REACT_APP_API_BASE_URL:"https://wisdomwarehouse.teamta.net",
    // BACKEND_URL:"https://wisdomwarehouse.teamta.net",
    // REACT_APP_SSO_APP_ID:"fc745f66-cce3-4033-840f-45da3cb84b60",
    REACT_APP_SSO_SERVER:"https://sso.kite.teamta.net",
    API_KEY_TECHS:"62b295ff4c9eb80c5666be007e59cf0a",
    TECH_GURU_TECH_API_URL:"http://techguru.teamta.net:8000/api/apiauth/getApiSkills",
    KITE_API_URL:"https://api.kite.teamta.net/",
    API_KEY_KITE_API:"39729090c76cae0ef779fb5792de721a"  
}
