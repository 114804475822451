import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Add from "../Components/Add";
import Edit from "../Components/Edit";
import View from "../Components/View";
import ExtendedView from "../Components/ExtendedView";

function AppRouter() {
  return (

      <Routes>
        <Route exact path="/*" element={<View />} />
        <Route path="/Add" element={<Add />} />
        <Route path="/ExtendedView" element={<ExtendedView />} />
        <Route path="/Edit" element={<Edit />} />
      </Routes>
  );
}

export default AppRouter;
