// import "./App.css";
// import AppRouter from "./Configs/Approuter";
// import { useEffect, useState } from "react";
// import authStore from "./Store/Authstore";
// import { useNavigate } from "react-router-dom";
// import {Spin} from "antd";

// function App() {
//   const [load,setLoad]=useState(true);
//   const navigate = useNavigate();
//   useEffect(() => {
//     authStore.ssoLogin.then((data) => {
//       setLoad(false);
//       if (data) {
//         navigate("/");
//       }
//     });
//   }, []);

//   return (
//   load ? <Spin /> :<AppRouter />
//   );
// }

// export default App;
import React from 'react';
import authStore from "./Store/Authstore";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "./Components/Spinner";
import {Spin} from "antd";
import AppRouter from "./Configs/Approuter";

function App() {
  const navigate = useNavigate();
  const [load,setLoad]=useState(true);
  useEffect(()=>{
   (async()=>{
    const data = await authStore.ssoLogin();
    setLoad(false);
    if (data) {
      navigate("/");
    }
   })(); 
  },[])

  return (
   load ? <Spin indicator={<Spinner />} style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100%",width:"100%"}} /> : <AppRouter />
    
  );
}

export default App;
