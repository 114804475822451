/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import api from './api';

class AuthService {
 
  ssoLogin = async() => {
    let response = await api.post(`api/auth/fakelogin`,null); 
    return response;
  };

  signOut = async () => {
    let response = await api.post(`api/auth/signout`,null);
    return response;
  };
}

export default new AuthService();