import React, { useEffect, useState } from "react";
import { Select, Modal, Spin, Input, Form, notification, Button, Row, Col } from "antd";
import "../Styles/Add.css";
import Icon, { CloseOutlined } from '@ant-design/icons'
import axios from "axios";
import { constants } from "../Utils/constants";
import utility from "../Utils/utility";
import { urls } from "../Utils/constants";
import Spinner from "./Spinner";
import { useForm } from "antd/es/form/Form";
const IssueForm = ({
  isModalvisible,
  setAddModal,
  editData,
  onCancel,
  getCurrentLesson,
  getLessons
}) => {
  //If there is data present in the editdata this will be used as default values or else empty strings to add data
  const [winOrIssue, setWinOrIssue] = useState(
    editData?.Outcome ? editData?.Outcome : ""
  );
  const [issueType, setIssueType] = useState(
    editData?.Type ? editData?.Type : ""
  );
  const [lessonTitle, setLessonTitle] = useState(
    editData?.Title ? editData?.Title :""
  );
  const [whatHappened, setWhatHappened] = useState(
    editData?.Details ? editData?.Details : ""
  );
  const [futureProjects, setFutureProjects] = useState(
    editData?.Lessons_learned ? editData?.Lessons_learned : ""
  );
  const [foundBy, setFoundBy] = useState(
    editData?.Reported_by ? editData?.Reported_by : ""
  );
  const [projectName, setProjectName] = useState(
    editData?.Project ? editData?.Project?.split(",") : ""
  );
  const [technologies, setTechnologies] = useState(
    editData?.Technologies?.split(",") ? editData?.Technologies?.split(",") : ""
  );

  const [time,setTime] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [lessonsForm] = useForm();

  useEffect(() => {
    setTime(new Date());
    lessonsForm.setFieldsValue({
      lessonTitle:lessonTitle?lessonTitle:undefined,
      howDidYouResolve: futureProjects ? futureProjects : undefined,
      whatHappened: whatHappened ? whatHappened : undefined,
      projectName: projectName.length&& projectName[0] !== ""&& projectName[0] !== "Not applicable"? projectName : undefined,
      technologies: technologies.length && technologies[0] !== "" && technologies[0] !== "Not applicable" ? technologies : undefined,
      lessonType: issueType ? issueType : undefined,
    });
  }, []);
  //setData is used to get the technology and projects are used for getting the projects
  const [data, setData] = useState(null);
  const [projects, setProjects] = useState(null);
  const [email, setEmail] = useState("");
  const [profilePic,setProfilePic] = useState("")

  //here the options is being destructured from antd select component
  const { Option } = Select;

  //here is fetching used for the spinner
  const [isFetching, setIsFetching] = useState(false);


  //this useEffect is used for getting current user get the techologies and projects for the dropDown
  useEffect(() => {
    // const currentUser = utility.getCurrentUser();
    const user = sessionStorage.getItem("getCurrentUser");
    const currentUser = JSON.parse(user);
    setFoundBy(currentUser?.name);
    setEmail(currentUser?.email);
    setProfilePic(currentUser?.profilePic);
  }, [issueType,whatHappened,futureProjects]);
  useEffect(()=>{
    getTechnologies();
    getProjectName();
  },[])

  //this function is used for adding new lessons
  const addLessons = () => {
    if (isSubmitting) {
      return; // Ignore if submission is already in progress
    }
    lessonsForm.validateFields().then((value) => {
      try {
        if (
          // winOrIssue &&
          issueType &&
          // technologies &&
          whatHappened &&
          futureProjects &&
          foundBy
          // &&projectName
        ) {
          // Set the flag variable to indicate submission in progress
          setIsSubmitting(true);

          axios
            .post(`${urls.BACKEND_URL}/api/lessons/add`, {
              lessonTitle:lessonTitle,
              type: issueType,
              technology: technologies,
              details: whatHappened,
              lessons_learned: futureProjects,
              reported_by: foundBy,
              project: projectName,
              email: email,
              title:lessonTitle,
              date:time,
              profilePic:profilePic
            })
            .then(() => {
                notification.success({
                  message: "Success",
                  description: "Submitted successfully",
                  duration: 2,
                });
                setAddModal(false);
                lessonsForm.resetFields();
                setTechnologies("")
                setProjectName("")
                // getLessons();
            })
            .finally(() => {
              // Reset the flag variable after submission is completed
              setIsSubmitting(false);
            });
        } else {
          console.log("failed");
        }
      } catch (err) {
        console.log("Error", err);
      }
    }).catch((error) => {
      console.log("error", error);
    });
  };

  //submit handler
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  //this is used for updating the existing lessons
  const updateLessons = (id) => {
    lessonsForm
      .validateFields()
      .then((value) => {
        try {
          console.log(projectName,technologies,"technologiestechnologies")
          if (
            // winOrIssue &&
            issueType &&
            // technologies &&
            whatHappened &&
            futureProjects &&
            foundBy
            //  &&
            // projectName
          ) {
            axios
              .put(`${urls.BACKEND_URL}/api/lessons/edit/${id}`, {
                lessonTitle:lessonTitle,
                outcome: winOrIssue,
                type: issueType,
                technology: technologies,
                details: whatHappened,
                lessons_learned: futureProjects,
                reported_by: foundBy,
                project: projectName,
                email: email,
              })
              .then(() => {
                getCurrentLesson();
                setAddModal(false);
                onCancel();
                notification.success({
                  message: "Success",
                  description: "Edited successfully",
                  duration: 2,
                });
                setTechnologies("")
                setProjectName("")
              });
          }
        } catch (err) {
          console.log("Error", err);
        }
      })
      .catch((err) => {
        console.log("eerorr::", err);
        // notification.error(err?.errorFields[0]?.errors[0]);
      });
  };



  const getTechnologies = async () => {
    try {
      await axios
        .get(`${urls.BACKEND_URL}/api/lessons/technologies`)
        .then((res) => {
          setData(res?.data);
        });
      console.log('res')
    } catch (err) {
      console.log("ERROR ", err);
    }
  };

  //functions for projects wich is using the kite api
  const getProjectName = async () => {
    try {
      setIsFetching(false);
      const projectDatas = await axios.get(
        `${urls.BACKEND_URL}/api/lessons/getProjects`
      );
      if (projectDatas.data !== null) {
        setProjects(projectDatas.data);
        setIsFetching(false);
      }
    } catch (err) {
      console.log("getProjectName  error", err);
    }
  };
  const handleSelectChangeType = (value) => {
    setIssueType(value);
  };

  const options = [
    { value: "Programmatic", label: constants.programmatic },
    { value: "Logical", label: constants.logical },
    { value: "Technical", label: constants.technical },
    { value: "Functional", label: constants.functional },
    { value: "Others", label: constants.others },
  ];
  return (
    <Modal
      // title={editData ? constants.edit : constants.add}
      title={
        <div className="w-100 mb-3 d-flex justify-content-between">
          <h4>{editData ? constants.edit : constants.add}</h4>
          <Button
            className="view-btn"
            closeIcon={null}
            onClick={()=>{setAddModal(false);
              lessonsForm.resetFields();
              setTechnologies("")
              setProjectName("")}}
          ><CloseOutlined /></Button>
        </div>
      }
      centered
      open={isModalvisible}
      onOk={() => setAddModal(false)}
      onCancel={() => setAddModal(false)}
      footer={false}
      maskClosable={false}
      closable={false}
      width={'55%'}
      className="custom-add-form"
    >
      <Spin spinning={isFetching} indicator={<Spinner />}>
        <Form
          className="add-form"
          onSubmit={handleSubmit}
          form={lessonsForm}
          layout="vertical"
        >
              <Form.Item
                className="label"
                label="Title"
                name="lessonTitle"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: "Please enter the title",
                  },
                ]}

              >
                <Input
                  placeholder="Title"
                  value={lessonTitle}
                  onChange={(event) => setLessonTitle(event.target.value)}
                  rows={4}
                  showCount={80}
                  maxLength={80}
                />
              </Form.Item>
          
          <Form.Item
            className="label"
            label={constants.whatHappened}
            name="whatHappened"
            rules={[
              {
                whitespace: true,
                required: true,
                message: "Please enter what happened",
              },
            ]}

          >
            <Input.TextArea
              className="txtFeild"
              placeholder="What happened?"
              showCount
              value={whatHappened}
              onChange={(event) => setWhatHappened(event.target.value)}
              maxLength={500}
              rows={4}
            />
          </Form.Item>
          <Form.Item
            className="label"
            label={constants.howDidYouResolve}
            name="howDidYouResolve"
            rules={[
              {
                whitespace: true,
                required: true,
                message: "Please enter how did you resolve",
              },
            ]}
          >
            <Input.TextArea
              className="txtFeild"
              placeholder="How did you resolve?"
              value={futureProjects}
              showCount
              type="text"
              onChange={(event) => setFutureProjects(event.target.value)}
              rows={4}
              maxLength={1000}
              style={{ whiteSpace: "pre-wrap" }}
            />
          </Form.Item>

          <Form.Item
            className="label"
            label={constants.technologies}
            name="technologies"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please choose the technologies",
          //   },
          // ]}
          >
            <Select
              allowClear
              mode="multiple"
              placeholder="Select the technologies"
              className="multiSelect"
              // popupClassName="select-dropdown"
              dropdownMatchSelectWidth={false}
              // defaultValue={(technologies &&technologies[0] !== "Not applicable") ? undefined : technologies}
              onChange={(selectedValues) => setTechnologies(selectedValues)}
              placement="bottomLeft"
              // maxTagCount={'responsive'}
            >
              {data?.skills?.map((value, index) => {
                return (
                  <Option key={index} value={value.name}>
                    {value.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col className="w-25">
              <Form.Item
                className="label"
                label={constants.lessonType}
                name="lessonType"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: "Please choose the lesson type",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Lesson type"
                  className="w-100"
                  // defaultValue={issueType !== "" ? issueType : undefined}
                  value={issueType}
                  onChange={handleSelectChangeType}
                // required
                >
                  {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="w-75">
              <Form.Item
                className="label"
                label={constants.projectName}
                name="projectName"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please choose project name",
              //   },
              // ]}
              >
                <Select
                  showSearch
                  allowClear
                  // mode="multiple"
                  placeholder="Please select"
                  className="w-100"
                  // defaultValue={projectName && projectName !== "Not applicable"? projectName : undefined}
                  onChange={(value) => {
                    setProjectName(value);
                  }}
                >
                  {projects?.project?.project?.map((value, index) => {
                    return (
                      <Option key={index} value={value.name}>
                        {value.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="d-flex justify-content-end">
          {editData ? null : (
            <Button
              type="primary"
              onClick={() => {
                addLessons();
              }}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Add
            </Button>
          )}
          {/*here this button will only pop up if edit is aailable*/}
          {editData ? (
            <Button
              type="primary"
              onClick={() => {
                updateLessons(editData.Sl_no);
              }}
            // className="submittedButton"
            >
              Save
            </Button>
          ) : null}
        </div>
      </Spin>
    </Modal>
  );
};
export default IssueForm;
