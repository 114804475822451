/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */
import Cookies from 'js-cookie';
import moment from 'moment';

class Utility {
  parseQueryString(path) {
    const parsedQueryString = {};

    const queryString = path?.split('?')[1];
    const queryStringList = queryString?.split('&');

    queryStringList?.forEach((item) => {
      const key = item.split('=')[0];
      const value = item.split('=')[1];
      parsedQueryString[decodeURIComponent(key)] = this.decodeValue(value);
    });

    return parsedQueryString;
  }

  decodeValue(value) {
    const decodedValue = decodeURIComponent(value || '');
    try {
      return JSON.parse(decodedValue);
    } catch (e) {
      return decodedValue;
    }
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledFutureDate(current) {
    return current && current > moment();
  }

  disabledFutureDateTime = () => {
    const hour = moment().hour();
    const minute = moment().minute();
    const seconds = moment().second();
    return {
      disabledHours: () => {
        if (hour < 23) {
          let start = hour;
          if (minute !== 0) {
            start += 1;
          }
          return this.range(start, 24);
        }
        return [];
      },
      disabledMinutes: () => {
        if (hour < 59) {
          if (moment().hour() === hour) {
            return this.range(minute + 1, 60);
          }
        }
        return [];
      },

      disabledSeconds: () => {
        if (moment().second() === seconds) {
          return this.range(seconds + 1, 60);
        }
        return [];
      },
    };
  };

  trimSpacesInValues(value) {
    if (value !== undefined || value !== null) {
      return value.trimLeft();
    }
    return '';
  }

  getCurrentUser = () => {
    
    const token = Cookies.get('token')
    if(token){
      const decodedToken = this.parseJwt(token)
      return decodedToken;
    }
    else{
      return null;
    }
  };

  // getRefreshTokenTime = () => {
  //   if (localStorage.refreshToken) {
  //     const refreshToken = this.parseJwt(localStorage.refreshToken);
  //     return refreshToken.expiryTime;
  //   }
  //   return null;
  // };

  convertCreatedDate = (sdate) => {
    let date = new Date(sdate);
    var datetime = moment(date).format('Do MMMM YYYY, h:mm a');
    return datetime;
  };
  videoDurationConverter = (duration) => {
    if (duration !== undefined) {
      let dateObj = new Date(duration);
      let hours = dateObj.getUTCHours();
      let minutes = dateObj.getUTCMinutes();
      let seconds = dateObj.getSeconds();
  
      return hours.toString().padStart(2, '0') + ':' + 
          minutes.toString().padStart(2, '0') + ':' + 
          seconds.toString().padStart(2, '0');
    
    }
  };

  calculateTraining = (start, end) => {
    const localEndTime = moment(end).local().format('MM/DD/YYYY ');
    const currentDate =  moment().local().format('MM/DD/YYYY ');
    const diffDays = moment(localEndTime, 'MM/DD/YYYY ').diff(
      moment(currentDate, 'MM/DD/YYYY '),
      'days'
    );
    if (diffDays < 0) {
      return 0;
    }
    return diffDays+1;
  };

  calculateTimeCreated = (createdTime) => {
    let date = new Date(createdTime);
    var datetime = moment(date).fromNow();
    return datetime
  }
}

export default new Utility();