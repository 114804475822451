import Cookies from 'js-cookie';
import authService from '../Services/authService';
import utility from '../Utils/utility';
import {constants, urls} from '../Utils/constants';

class AuthStore {
  constructor() {
    this.error = '';
    this.authListener = null;
    this.currentUser = null;
    this.refreshExpireTime = null;
    this.search = '';
    this.currentPage = constants.defaultPageNumber;
  }

  addAuthListener(callbackListener) {
    this.authListener = callbackListener;
    this.currentUser = utility.getCurrentUser();
    
    // this.refreshExpireTime = Utility.getRefreshTokenTime();
    if (
      this.currentUser &&
      this.refreshExpireTime &&
      this.refreshExpireTime < new Date().getTime()
    ) {
      this.signOut();
    } else if (this.currentUser) {
      if (this.authListener) {
        this.authListener(this.currentUser);
      }
    } else if (this.authListener) {
      this.authListener(this.currentUser);
    }
  }

  async ssoLogin() {
    let response = await authService.ssoLogin();
      const token = response?.data?.token;
      if (token) {
        const decodedToken = utility.parseJwt(token);
        this.currentUser = decodedToken;
        localStorage.setItem('token', token);
        return decodedToken;
      }
      else{
        return "error"
      }
   }

  async signOut() {
    await authService.signOut();
    localStorage.removeItem('token');
    Cookies.remove('token', { domain: process.env.REACT_APP_DOMAIN_NAME });
    if (
      process.env.REACT_APP_SSO_APP_ID != undefined &&
      urls.REACT_APP_SSO_SERVER != undefined
    ) {
      window.location.href =
       urls.REACT_APP_SSO_SERVER+
        '/sso/auth/login?appId=' +
        process.env.REACT_APP_SSO_APP_ID;
    }
  }
}

export default new AuthStore();
