import React, { useState, useEffect } from "react";
import { Select, Modal, Input, Form } from "antd";
import "../Styles/Add.css";
import axios from "axios";
import { urls } from "../Utils/constants";
import { constants } from "../Utils/constants";

const IssueForm = ({ editData, isModalvisible, setEditModal, onCancel }) => {
  const [data, setData] = useState();
  const [winOrIssue, setWinOrIssue] = useState(editData.Outcome);
  const [issueType, setIssueType] = useState(editData.Type);
  const [whatHappened, setWhatHappened] = useState(editData.Details);
  const [technologies, setTechnologies] = useState(
    editData.Technologies.split(",")
  );
  const [futureProjects, setFutureProjects] = useState(
    editData.Lessons_learned
  );
  const [foundBy, setFoundBy] = useState(editData.Reported_by);
  const [projectName, setProjectName] = useState(editData.Project);
  useEffect(() => {
    getTechnologies();
  }, []);
  const getTechnologies = () => {
    try {
      axios.get(`${urls.BACKEND_URL}/api/lessons/technology`).then((res) => {
        setData(res.data);
      });
    } catch (err) { }
  };
  const options = data?.map((item) => ({
    value: item.technology,
  }));
  const updateLessons = (id) => {
    try {
      axios
        .put(`${urls.BACKEND_URL}/api/lessons/edit/${id}`, {
          outcome: winOrIssue,
          type: issueType,
          technology: technologies,
          details: whatHappened,
          lessons_learned: futureProjects,
          reported_by: foundBy,
          project: projectName,
        })
        .then(() => {
          console.log("SUCCESS");
          setEditModal(false);
        });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Modal
      title="Edit"
      centered
      open={isModalvisible}
      onOk={() => setEditModal(false)}
      onCancel={() => {
        setEditModal(false);
        onCancel();
      }}
      footer={false}
      width={'45%'}
    >
      <Form
        className="add-form"
        onSubmit={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          className="label"
          label="Title"
          name="whatHappened"
          rules={[
            {
              required: true,
              message: "Please enter the title",
            },
          ]}

        >
          <Input 
          type="text" 
          value={projectName}
          onChange={(event) => setProjectName(event.target.value)} />
        </Form.Item>
      </Form>
      <form className="add-form" onSubmit={handleSubmit}>
        <label className="label">{constants.projectName}</label>
        <input
          className="inptFeild"
          type="text"
          value={projectName}
          onChange={(event) => setProjectName(event.target.value)}
          required
        />
        <div className="d-flex">
          <div>
            <label className="label">{constants.status}</label>

            <select
              className="inptFeild"
              value={winOrIssue}
              onChange={(event) => setWinOrIssue(event.target.value)}
              required
            >
              <option value="" disabled hidden>
                -- Select --
              </option>
              <option value="win">{constants.open}</option>
              <option value="issue">{constants.close}</option>
            </select>
          </div>
          <div>
            <label className="label">{constants.lessonType}</label>
            <select
              className="inptFeild"
              value={issueType}
              onChange={(event) => setIssueType(event.target.value)}
              required
            >
              {" "}
              <option value="" disabled hidden>
                -- Select --
              </option>
              <option value="Programmatic">{constants.programmatic}</option>
              <option value="Logical">{constants.logical}</option>
              <option value="Technical">{constants.technical}</option>
              <option value="Functional">{constants.functional}</option>
              <option value="Others">{constants.others}</option>
            </select>
          </div>
        </div>
        <label className="label">{constants.technologies}</label>

        <Select
          mode="multiple"
          placeholder="Please select"
          style={{
            width: "100%",
          }}
          defaultValue={technologies}
          options={options}
          onChange={(selectedValues) => setTechnologies(selectedValues)}
        />

        <label className="label">{constants.whatHappened}</label>
        <textarea
          className="txtFeild"
          value={whatHappened}
          onChange={(event) => setWhatHappened(event.target.value)}
          rows={4}
          required
        />
        <label className="label">{constants.howDidYouResolve}</label>
        <textarea
          className="txtFeild"
          value={futureProjects}
          onChange={(event) => setFutureProjects(event.target.value)}
          rows={4}
          required
        />
        <label className="label">{constants.foundByWhom}</label>
        <input
          className="inptFeild"
          type="text"
          value={foundBy}
          onChange={(event) => setFoundBy(event.target.value)}
          required
        />

        <button
          type="submit"
          onClick={() => {
            updateLessons(editData.Sl_no);
            onCancel();
          }}
          className="submittedButton"
        >
          Submit
        </button>
      </form>
    </Modal>
  );
};
export default IssueForm;
